<template>
  <div class="container-fluid">
    <div class="row L1">
      <div class="container">
        <div class="row info-title visible-xs visible-sm">
          <h1>龟斯的风光摄影助手</h1>
          <p>免费无广告、无须下载、无须安装</p>
          <div class="btnbox">
            <img  draggable="false" src="@/assets/img/miniprogram/code.png" alt="" srcset="" >
            <p>使用微信扫码体验</p>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-6 leftbox">
            <div class="phone" :class="show?'show':''">
              <img  draggable="false" class="bg hidden-xs hidden-sm" src="@/assets/img/miniprogram/bg.jpg" alt="">
              <div class="panel">
                <div class="time">{{date}}</div>
                <div class="location">
                  <div class="locationbox">
                    <img  draggable="false" src='@/assets/img/miniprogram/choose.png'></img>
                    <span>纬度 31.3188</span> / <span>经度 120.6750°</span> / <span>海拔 6m</span>
                  </div>
                </div>
                <div class="locationdetail">江苏省 苏州市 工业园区 斜塘街道 </div>
                <div class="recommend">
                  <div class="item">
                    <div class="logo">
                      <img  draggable="false" src='@/assets/img/miniprogram/calc.png' />
                    </div>
                    <span >曝光计算</span>
                  </div>
                  <div class="item">
                    <div class="logo">
                      <img  draggable="false" src='@/assets/img/miniprogram/torch2.png' />
                    </div>
                    <span >补光灯</span>
                  </div>
                  <div class="item">
                    <div class="logo">
                      <img  draggable="false" src='@/assets/img/miniprogram/flash2.png' />
                    </div>
                    <span >光绘笔</span>
                  </div>
                  <div class="item">
                    <div class="logo">
                      <img  draggable="false" src='@/assets/img/miniprogram/campass.png' />
                    </div>
                    <span >对极轴</span>
                  </div>
                  <div class="item">
                    <div class="logo">
                      <img  draggable="false" src='@/assets/img/miniprogram/collect.png' />
                    </div>
                    <span >收藏夹</span>
                  </div>
                  <div class="item">
                    <div class="logo">
                      <img  draggable="false" src='@/assets/img/miniprogram/flower.png' />
                    </div>
                    <span >景深计算</span>
                  </div>
                </div>
                <img  draggable="false" class="block block4" src="@/assets/img/miniprogram/4.jpg" alt="" srcset="">
                <img  draggable="false" class="block block5" src="@/assets/img/miniprogram/5.jpg" alt="" srcset="">
                <img  draggable="false" class="block block6" src="@/assets/img/miniprogram/6.jpg" alt="" srcset="">
                <img  draggable="false" class="block block1" src="@/assets/img/miniprogram/1.jpg" alt="" srcset="">
                <img  draggable="false" class="block block2" src="@/assets/img/miniprogram/2.jpg" alt="" srcset="">
                <img  draggable="false" class="block block3" src="@/assets/img/miniprogram/3.jpg" alt="" srcset="">
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-6 hidden-xs hidden-sm rightbox flex ">
            <div class="rightinbox">
              <h1>龟斯的风光摄影助手</h1>
              <p>免费无广告、无须下载、无须安装</p>
              <div class="btnbox">
                <img  draggable="false" src="@/assets/img/miniprogram/code.png" alt="" srcset="" >
                <p>使用微信扫码体验</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row L2">
      <div class="bg"></div>
      <div class="container">
        <div class="row">
          <div class="col-xs-6 col-md-4 item">
            <div class="icon">
              <img  draggable="false" src="@/assets/img/miniprogram/l2sun.svg" alt="" srcset="">
            </div>
            <h3>日月时间</h3>
            <p>根据当前地理位置，并对天文时间的计算</br>准确获得每天的日出日落、黄金拍摄时段</p>
          </div>
          <div class="col-xs-6 col-md-4 item">
            <div class="icon">
              <img  draggable="false" src="@/assets/img/miniprogram/l2stars.svg" alt="" srcset="">
            </div>
            <h3>银河起落</h3>
            <p>所选日期当天的银河起落情况</p>
          </div>
          <div class="col-xs-6 col-md-4 item">
            <div class="icon">
              <img  draggable="false" src="@/assets/img/miniprogram/l2moon.svg" alt="" srcset="">
            </div>
            <h3>月相</h3>
            <p>月亮升起和落下的时间点</br>当天的农历日期、月相</p>
          </div>
          <div class="col-xs-6 col-md-4 item">
            <div class="icon">
              <img  draggable="false" src="@/assets/img/miniprogram/l2campass.svg" alt="" srcset="">
            </div>
            <h3>银心方位</h3>
            <p>分钟级显示所选当天银河的精确方位及高度角</br>并给出指南针指示方位</p>
          </div>
          <div class="col-xs-6 col-md-4 item">
            <div class="icon">
              <img  draggable="false" src="@/assets/img/miniprogram/l2cloud.svg" alt="" srcset="">
            </div>
            <h3>天气预报</h3>
            <p>近15天全球天气预报以及72小时分钟级预报</br>包括湿度、空气质量、云量、降雨概率等信息</p>
          </div>
          <div class="col-xs-6 col-md-4 item">
            <div class="icon">
              <img  draggable="false" src="@/assets/img/miniprogram/l2wave.svg" alt="" srcset="">
            </div>
            <h3>潮汐涨落</h3>
            <p>近10日距离所选地点最近的潮汐涨落信息</br>支持全球400多个主要港口</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row L3">
      <div class="left hidden-xs hidden-sm">
        <div class="item" @mouseover="showIndex = 1">
          <div class="infobox">
            <div class="title">曝光计算器</div>
            <p>试拍一张很满意，改了光圈那么快门应该设置多少？</p>
            <transition name="fade">
              <div class="detail" :class="showIndex == 1?'active':''">
                <p>我们在拍摄长曝光时，需要先使用高iso、大光圈、短快门来试拍一张以获得大致的构图和画面亮度；然后再使用低iso、小光圈、长快门来拍摄正式的摄影作品</p>
                <p>先设置试拍参数，然后设置实拍参数（快门可随意设置），工具会给出相同曝光水平下应当设置多少快门时间。该功能同时内置了计时器辅助用户手动曝光</p>
                <!-- <a href="http://" target="_blank">查看视频教程</a> -->
              </div>
            </transition>
          </div>
          <div class="iconbox">
            <img  draggable="false" src="@/assets/img/miniprogram/calc.png" alt="" srcset="">
          </div>
        </div>
        <div class="item" @mouseover="showIndex = 2">
          <div class="infobox">
            <div class="title">补光灯</div>
            <p>星空人像好难拍，人脸漆黑一片怎么办？</p>
            <transition name="fade">
              <div class="detail" :class="showIndex == 2?'active':''">
                <p>弱光环境下用手机的手电筒不仅太亮，而且不能调节颜色，看起来就很假，而且很容易就过曝了。这时候可以利用手机屏幕来补光</p>
                <p>左右滑动更换颜色，上下滑动调节屏幕亮度。如果是为人脸补光请使用黄色</p>
                <!-- <a href="http://" target="_blank">查看视频教程</a> -->
              </div>
            </transition>
          </div>
          <div class="iconbox">
            <img  draggable="false" src="@/assets/img/miniprogram/torch2.png" alt="" srcset="">
          </div>
        </div>
        <div class="item" @mouseover="showIndex = 3">
          <div class="infobox">
            <div class="title">光绘笔</div>
            <p>看他们光绘写字的照片好酷炫，也想试试？</p>
            <transition name="fade">
              <div class="detail" :class="showIndex == 3?'active':''">
                <p>光绘文字难点在于左右文字的镜像非常容易画错且传统手电筒开关不好控制</p>
                <p>使用该工具只需要面向相机，用手机闪光灯作为画笔，按住按钮点亮，松手即灭，照着屏幕上的镜像文字绘制即可</p>
                <!-- <a href="http://" target="_blank">查看视频教程</a> -->
              </div>
            </transition>
          </div>
          <div class="iconbox">
            <img  draggable="false" src="@/assets/img/miniprogram/flash2.png" alt="" srcset="">
          </div>
        </div>
      </div>
      <div class="center" >
        <div class="phone" :class="show?'show':''">
          <div class="movebox" :style="movePic">
            <img  draggable="false" class="bg" src="@/assets/img/miniprogram/l31.jpg" alt="">
            <img  draggable="false" class="bg" src="@/assets/img/miniprogram/l32.jpg" alt="">
            <img  draggable="false" class="bg" src="@/assets/img/miniprogram/l33.jpg" alt="">
            <img  draggable="false" class="bg" src="@/assets/img/miniprogram/l34.jpg" alt="">
            <img  draggable="false" class="bg" src="@/assets/img/miniprogram/l35.jpg" alt="">
            <img  draggable="false" class="bg" src="@/assets/img/miniprogram/l36.jpg" alt="">
          </div>
        </div>
        <div class="info visible-xs visible-sm">
          <template v-if="showIndex == 1">
            <div class="title">曝光计算器</div>
            <p>试拍一张很满意，改了光圈那么快门应该设置多少？</p>
          </template>
          <template v-if="showIndex == 2">
            <div class="title">补光灯</div>
            <p>星空人像好难拍，人脸漆黑一片怎么办？</p>
          </template>
          <template v-if="showIndex == 3">
            <div class="title">光绘笔</div>
            <p>看他们光绘写字的照片好酷炫，也想试试？</p>
          </template>
          <template v-if="showIndex == 4">
            <div class="title">赤道仪极轴粗对工具</div>
            <p>看不到北极星不会对极轴了吧？</p>
          </template>
          <template v-if="showIndex == 5">
            <div class="title">机位收藏夹</div>
            <p>看到别人拍照的机位想下次去？</p>
          </template>
          <template v-if="showIndex == 6">
            <div class="title">天气预报</div>
            <p>不知道想去的地方是晴天还是下雨？</p>
          </template>
        </div>
      </div>
      <div class="right hidden-xs hidden-sm">
        <div class="item" @mouseover="showIndex = 4">
          <div class="iconbox">
            <img  draggable="false" src="@/assets/img/miniprogram/campass.png" alt="" srcset="">
          </div>
          <div class="infobox">
            <div class="title">赤道仪极轴粗对工具</div>
            <p>看不到北极星不会对极轴了吧？</p>
            <transition name="fade">
              <div class="detail" :class="showIndex == 4?'active':''">
                <p>有些时候看不到北极星或者不方便使用指星笔，我们只能用复杂的漂移法。但是星野拍摄大多数情况下并不需要那么高的极轴精度</p>
                <p>手机放在赤道仪顶部，根据指南针调整方向和俯仰，当两个三角重合时即已大致对准北极星（夹角小于0.5°为佳）</p>
                <!-- <a href="http://" target="_blank">查看视频教程</a> -->
              </div>
            </transition>
          </div>
        </div>
        <div class="item" @mouseover="showIndex = 5">
          <div class="iconbox">
            <img  draggable="false" src="@/assets/img/miniprogram/collect.png" alt="" srcset="">
          </div>
          <div class="infobox">
            <div class="title">机位收藏夹</div>
            <p>看到别人拍照的机位想下次去？</p>
            <transition name="fade">
              <div class="detail" :class="showIndex == 5?'active':''">
                <p>网上看到别人拍摄的图片非常不错，想收藏起来下次再去拍个同款，但又担心下回找不到了</p>
                <p>在这里你就可以自行添加、分享、收藏别人或是自己的独家机位。支持添加图片、经纬度、星级、说明等信息，给自己存一个档，随时查阅。一个好位置值得分享哦！</p>
                <!-- <a href="http://" target="_blank">查看视频教程</a> -->
              </div>
            </transition>
          </div>
        </div>
        <div class="item" @mouseover="showIndex = 6">
          <div class="iconbox">
            <img  draggable="false" src="@/assets/img/miniprogram/forcast.png" alt="" srcset="">
          </div>
          <div class="infobox">
            <div class="title">天气预报</div>
            <p>不知道想去的地方是晴天还是下雨？</p>
            <transition name="fade">
              <div class="detail" :class="showIndex == 6?'active':''">
                <p>我们总是很难精确的知道拍摄地的天气，查到的一般都是大概的天气，反而越看心里越没底</p>
                <p>支持7 × 24小时逐小时精确预报，15天长程预报，涵盖温度、风速、湿度、降水、云量、露点温度等和拍摄息息相关的信息</p>
                <p>天气数据提供：QWeather / NMC / ECMWF，尽力保证预报的准确性</p>
                <!-- <a href="http://" target="_blank">查看视频教程</a> -->
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <div class="row L4">
      <img  draggable="false" src="@/assets/img/miniprogram/bg2.jpg" class="bg"></img>
      <div class="container">
        <div class="row text-center">
          <h1>精确的地图选点</h1>
          <p>支持地图 / 卫星 / 导航</p>
          <div class="tips">
            <p>坐标基于GWS84标准，并对GCJ02坐标进行了转换</p>
            <p>可以将大疆app中坐标直接复制进来即可看到精确地点</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row Log">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <h1>软件说明</h1>
            <p>小程序依托于微信运行，支持Android、iOS，可运行于Windows和macOS，由于桌面端缺少必要的硬件（陀螺仪、GPS等），功能可能有轻微不同。</p>
            <p>部分功能需要网络连接，推荐联网运行。</p>

            <h1>更新日志</h1>
            <p>20211228 3.8.0 增加景深/超焦距计算功能</p>
            <p>20211227 3.7.0 曝光计算改版：优化交互和见面展示，增加拍摄建议</p>
            <p>20211103 3.6.1 天气预报界面改版，更换天气、潮汐信息服务提供商</p>
            <p>20210918 3.5.5 修复PC端坐标显示错误的问题；修复PC端无法查看潮汐的问题</p>
            <p>20210903 3.5.1 曝光计算单一picker更改为三联picker</p>
            <p>20210518 3.5.0 地图支持输入坐标点查找位置</p>
            <p>20210518 3.4.0 增加曝光计算、光绘笔功能，界面改版</p>
            <p>20210305 3.3.0 增加机位收藏夹功能</p>
            <p>20201208 3.2.0 增加天气预报</p>
            <p>20201129 3.1.0 增加潮汐预报，界面改版</p>
            <p>20190509 2.0.11 优化性能，增加地理位置显示</p>
            <p>20190302 2.0.11 优化性能</p>
            <p>20190217 2.0.0 代码重构，增加日月时间、银河方位、月相功能</p>
            <p>20171206 1.0.0 第一版，提供照片展示功能</p>
          </div> 
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'miniprogram',
  metaInfo: {
    title: '龟斯的风光摄影助手 小程序', // set a title
    meta: [{             // set meta
      name: 'keywords',
      content: '小程序,微信小程序,日出,日落,潮汐,数据查询,天气,天气预报,摄影,摄像,风光摄影,拍摄规划,计划,移动端,app,流星雨,银河,天象,观星,指南针,光绘笔,赤道仪'
    },
    {
      name: 'description',
      content: '全能摄影工具，工具囊括了日出日落、银河时间、月相、天气预报、潮汐涨落、曝光计算、光绘笔、手机对极轴、景深计算'
    }],
    link: []
  },
  props: {
    
  },
  computed:{
    movePic(){
      return {
        width:6 * 375 + 'px',
        left:(this.showIndex - 1) * -375 + 'px'
      }
    }
  },
  data(){
    return {
      publicPath: process.env.BASE_URL,
      date:"",
      show:false,
      showIndex:1,
    }
  },
  mounted(){
    this.date = new Date().format("yyyy-MM-dd")
    setTimeout(()=>{
      this.show = true
    },500)
    if(window.innerWidth < 970){
      setInterval(()=>{
        if(this.showIndex == 5){
          this.showIndex = 1
        }else{
          this.showIndex++
        }
      },5000)
    }
  },
  methods:{
    download(link){
      var a = document.createElement('a');
      a.href = link;//下载文件地址
      a.click(); 
    },
    showTotrial(index){
      this.showDetail = index
    }
  }

}
</script>
<style lang="less" scoped>
	.flex{
		display: flex;
		align-items: center;
		justify-content: flex-start;	
	}
.container-fluid{
  width: 100%;
  padding: 0;
  margin: 0;
  .row{
    margin: 0;
    padding: 0;
  }
  .L1{
    color: #fff;
    padding-top: 100px;
    height: 1500px;
    // background-image: linear-gradient(34deg, #3d4b4e, #58676a, #748487, #91a2a6);
    background-image: linear-gradient(25deg, #243f4b, #566c71, #8a9c9a, #c1cfc4);
    @media (max-width: 970px) { 
      height: 2000px;
     }
    .container{
      padding: 0;
      .leftbox{
        height: 100%;
        .phone{
          position: absolute;
          left: 20%;
          top: -290px;
          transform: translate(-50%,0)  scale(0.5);
          perspective: 3000px;
          *{
            transition: all 3s ease;
          }
          .bg{
            z-index: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%) rotate3d(0.5,3.5,-0.9,24deg);
            width: 750px;
            height: 1336px;
            border-radius: 50px;
            box-shadow: 0px 0px 20px rgba(0,0,0,0.3);
            display: none;
          }
          .panel {
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            height: auto;
            position: relative;
            width: 750px;
            height: 1336px;
            background-color: rgba(255,255,255,0.3);
            margin: 0;
            border-radius: 50px;
            transform: rotate3d(0.5,3.5,-0.9,24deg);
            .time {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-top: 80px;
              color: #333;
              font-size: 90px;
              font-weight: 100;
              padding: 0 0 0 30px;
              text-shadow: 0px 0px 0px rgba(0,0,0,0.1);
            }
            .location {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-size: 24px;
              line-height: 1.5;
              color: #333;
              width: 100%;
              padding-left: 30px;
              text-shadow: 0px 0px 0px rgba(0,0,0,0.1);
              img {
                width: 50px;
                height: 50px;
                position: absolute;
                left: 0;
                top: 10px;
              }
              .locationbox {
                padding-left: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                  margin: 0 10px;
                }
              }
              .reset {
                font-size: 20px;
                border: 2px solid #333;
                padding: 0 5px;
                border-radius: 5px;
              }
            }
            .locationdetail {
              font-size: 24px;
              line-height: 1.5;
              color: #333;
              height: 36px;
              width: 100%;
              padding-left: 90px;
              margin-bottom: 20px;
              text-shadow: 0px 0px 0px rgba(0,0,0,0.1);
            }
            .recommend{
              padding: 0 30px 20px;
              width: 100%;
              .flex;
              .item{
                margin: 0 10px;
                .flex;
                flex-direction: column;
                .logo{
                  width: 80px;
                  height: 80px;
                  border-radius: 50%;
                  backdrop-filter: blur(10px);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  box-shadow: 0 0 20px rgba(0,0,0,0.3);
                  &:after{
                    content: "";
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background-color: rgba(255,255,255,0.3);
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 5;
                  }
                  &.active:after{
                    background-color: rgba(255,255,255,0.6);
                  }
                }
                img{
                  width: 50px;
                  height: 50px;
                  z-index: 10;
                }
                span{
                  color: #333;
                  font-size: 22px;
                  margin-top: 5px;
                }
              }
              
            }
            .block{
              width: 750px;
              border-radius: 35px;
            }
            .block1{
              position: absolute;
              left: -200px;
              top: 450px;
              &:hover{
                transition: all 0.5s ease;
                top: 400px;
                z-index: 10;
              }
            }
            .block2{
              position: absolute;
              left: -200px;
              top: 660px;
              &:hover{
                transition: all 0.5s ease;
                top: 610px;
                z-index: 10;
              }
            }
            .block3{
              position: absolute;
              left: -200px;
              top: 870px;
              &:hover{
                transition: all 0.5s ease;
                top: 820px;
                z-index: 10;
              }
            }
            .block4{
              position: absolute;
              left: 80px;
              top: 500px;
              &:hover{
                transition: all 0.5s ease;
                top: 450px;
                z-index: 10;
              }
            }
            .block5{
              position: absolute;
              left: 80px;
              top: 710px;
              &:hover{
                transition: all 0.5s ease;
                top: 660px;
                z-index: 10;
              }
            }
            .block6{
              position: absolute;
              left: 80px;
              top: 920px;
              &:hover{
                transition: all 0.5s ease;
                top: 870px;
                z-index: 10;
              }
            }
          }
          &.show{
            .bg{
              box-shadow: -110px 40px 80px rgba(0,0,0,0.3);
            }
            .panel{
              .time{
                transform: translate(73px,-9px);
                text-shadow: -50px 20px 20px rgba(0,0,0,0.3);
              }
              .location,.locationdetail{
                transform: translate(73px,-9px);
                text-shadow: -110px 32px 20px rgba(0,0,0,0.5);
              }
              .recommend{
                transform: translate(73px,-9px);
                text-shadow: -50px 20px 20px rgba(0,0,0,0.3);
                .logo{
                  box-shadow: -50px 20px 40px rgba(0,0,0,0.2);
                }
              }
              .block1{
                transform: translate(70px,-9px);
                box-shadow: -50px 20px 50px rgba(0,0,0,0.2);
              }
              .block2{
                transform: translate(170px,49px);
                box-shadow: -50px 20px 50px rgba(0,0,0,0.2);
              }
              .block3{
                transform: translate(270px,99px);
                box-shadow: -50px 20px 50px rgba(0,0,0,0.2);
              }
              .block4{
                transform: translate(370px,-9px);
                box-shadow: -50px 20px 50px rgba(0,0,0,0.2);
              }
              .block5{
                transform: translate(470px,49px);
                box-shadow: -50px 20px 50px rgba(0,0,0,0.2);
              }
              .block6{
                transform: translate(570px,99px);
                box-shadow: -50px 20px 50px rgba(0,0,0,0.2);
              }
            }
          }
        }
      }
      .info-title{
        font-weight: 100;
        margin-top: 50px;
        h1{
          text-align: center;
          font-size: 40px;
          font-weight: 100;
          text-shadow: 0px 0px 20px rgba(0,0,0,0.8);
        }
        p{
          font-size: 18px;
          text-align: center;
          text-shadow: 0px 0px 20px rgba(0,0,0,0.8);
        }
        .btnbox{
          text-align: center;
          margin-top: 20px;
          img{
            width: 200px;
            height: 200px;
          }
          p{
            font-size: 16px;
            color: #333;
            font-weight: 500;
            text-shadow: none;
          }
        }
      }
      .rightbox{
        flex-direction: column;
        .rightinbox{
          .info-title;
          margin-top: 200px;
          h1{
            font-size: 60px;
          }
          p{
            font-size: 24px;
          }
          .btnbox{
            margin-top: 60px;
          }
        }
      }
    }
  }
  .L2{
    top: -200px;
    .bg{
      width: 200%;
      height: 700px;
      background: #fff;
      position: absolute;
      left: 50%;
      top: -300px;
      transform: rotateZ(-6deg) translateX(-50%);
    }
    .item{
      .flex;
      flex-direction: column;
      margin-bottom: 50px;
      height: 210px;
      .icon{
        width: 60px;
        height: 60px;
        border: 1px solid #000;
        border-radius: 50%;
        .flex;
        justify-content: center;
        flex-shrink: 0;
        img{
          width: 30px;
          height: 30px;
        }
      }
      h3{
        text-align: center;
        margin-bottom: 20px;
      }
      p{
        text-align: center;
        color: #999;
      }
    }
  }
  .L3{
    background-color: #eee;
    width: 100%;
    display: flex;
    height: 1000px;
    overflow: hidden;
    .left{
      width: 100%;
      flex-grow: 1;
      .flex;
      justify-content: center;
      flex-direction: column;
      .item{
        text-align: right;
        .detail{
          padding-left: 20px;
          p{
            border-right:2px solid #566c71;
            padding-right: 10px;
          }
        }
      }
    }
    .right{
      width: 100%;
      flex-grow: 1;
      .flex;
      justify-content: center;
      flex-direction: column;
      .item{
        text-align: left;
        .detail{
          padding-right: 20px;
          p{
            border-left:2px solid #566c71;
            padding-left: 10px;
          }
        }
      }
    }
    
    .item{
      margin: 30px 0;
      width: 100%;
      cursor: pointer;
      .flex;
      align-items: flex-start;
      .infobox{
        flex-grow: 1;
        .title{
          font-size: 32px;
          font-weight: 300;
        }
        p{
          font-size: 16px;
          color: #999;
        }
        a{
          font-size: 16px;
        }
        .detail{
          overflow: hidden;
          height: 0px;
          transition: all .5s;
          margin-top: 5px;
          margin-bottom: 5px;
          &.active{
            height: 210px;
          }
          a{
            font-size: 16px;
            line-height: 1;
            padding: 8px 20px;
            border: 1px solid #566c71;
            color: #566c71;
            border-radius: 20px;
            margin: 10px 0;
            display: inline-block;
          }
        }
      }
      .iconbox{
        flex-shrink: 0;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        .flex;
        justify-content: center;
        background-color: rgba(255,255,255,0.6);
        box-shadow: 0 0 20px rgba(0,0,0,0.1);
        margin: 0 30px;
        img{
          width: 40px;
          height: 40px;
        }
      }
    }
    .center{
      width: 400px;
      flex-shrink: 0;
      .phone{
        position: absolute;
        left: 50%;
        top: 50%;
        transition: all 2s ease;
        transform: translate(-50%,-50%) scale(0.9);
        box-shadow: 0 0 2px rgba(0,0,0,0.5);
        flex-shrink: 0;
        width: 375px;
        height: 812px;
        box-sizing: content-box;
        border: 8px solid #000;
        border-radius: 40px;
        overflow: hidden;
        .movebox{
          transition: all 0.5s ease;
          img{
            display: inline-block;
            width: 375px;
          }
        }
        &.show{
          transform: translate(-50%,-50%) scale(0.8);
          box-shadow: 0 0 20px rgba(0,0,0,0.5);
        }
      }
      .info{
        width: 100vw;
        box-sizing: border-box;
        text-align: center;
        margin-top: 860px;
        .title{
          font-size: 32px;
          font-weight: 300;
        }
        p{
          font-size: 16px;
          color: #999;
          padding: 0 20px;
          width: 100%;
          word-break: break-all;
          word-wrap: break-word;
          white-space: pre-wrap;
        }
      }
    }
  }
  .L4{
    height: 800px;
    .flex;
    justify-content: center;
    overflow: hidden;
    .bg{
      width: 120vw;
      position: absolute;
      left: 50%;
      top: 0px;
      transform: translateX(-50%);
    }
    .container{
      h1{
        font-size: 49px;
        font-weight: 300;
        text-shadow: 0 0 20px rgba(255,255,255,1);
      }
      .tips{
        margin-top: 30px;
        p{
          margin: 0;
          color: #666;
          text-shadow: 0 0 20px rgba(255,255,255,1);
        }
      }
    }
  }
}
</style>